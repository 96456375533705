import React from "react";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step="13a"
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 13a</H1>

        <p>This <em className="italic">Scientific American</em> article tells the story of a woman who went to a doctor for a medical procedure. As you read, think about the benefits and risks that this woman faced. You can stop reading when you get to the section, "When is a skin cream a drug?"</p>
        <Button
          action="secondary"
          className="mt-5"
          color="purple"
          href="https://www.scientificamerican.com/article/stem-cell-cosmetics/"
          icon="external"
          title="Go to article"
        >
          Go to article
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

